export interface IFilterItem {
    _key: string;
    name: string;
    month: number;
    count: number;
    year: number;
    children: {
        _key: string;
        name: string;
        count: number;
    }[];
}

export enum FilterTypesInResponse {
    TRAINING_TYPE = 'CourseType',
    CITY = 'City',
    CATEGORY = 'Category',
    MONTH = 'Month',
    LECTURER = 'Person',
    CONFERENCE_TYPE = 'ConferenceType',
}

export enum FilterTypesInUrl {
    TRAINING_TYPE = 'kurz_typ',
    CITY = 'mesto',
    CATEGORY = 'kategorie',
    LECTURER = 'lektor',
    MONTH = 'mesic',
    YEAR = 'rok',
    CONFERENCE_TYPE = 'konference_typ',
    ARCHIVED = 'archived',
    UPCOMING = 'aktualne',
    PAGE = 'strana'
}

export enum CourseTypes {
    UPCOMING = 'aktualne',
    ONLINE = 'online',
    ATTENDANCE = 'prezencne',
    ARCHIVED = 'zaznamy',
}

export const MonthLabels = {
    1: 'leden',
    2: 'únor',
    3: 'březen',
    4: 'duben',
    5: 'květen',
    6: 'červen',
    7: 'červenec',
    8: 'srpen',
    9: 'září',
    10: 'říjen',
    11: 'listopad',
    12: 'prosinec',
};

export enum ConferenceTypes {
    ARCHIVED = 'archived',
    UPCOMING = 'upcoming',
}
