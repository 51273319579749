import moment from 'moment';
import { ITrainingItemDate } from '../interfaces/Calendar';

export const secondsToHMS = (secs: number) => {
    const sec_num = parseInt(String(secs), 10);
    const hours = Math.floor(sec_num / 3600);
    const minutes = Math.floor(sec_num / 60) % 60;
    const seconds = sec_num % 60;

    return [hours, minutes, seconds]
        .map((v) => (v < 10 ? '0' + v : v))
        .filter((v, i) => v !== '00' || i > 0)
        .join(':');
};

export const closestDateFrom = (eventDates: ITrainingItemDate[]): ITrainingItemDate => {
    const closestDate = eventDates.find((eventDate) => {
        const diff = moment(eventDate.date.from).diff(moment(), 'minutes');

        return diff >= 0;
    });

    return closestDate;
};

export const formatNotificationDate = (date: Date): string => {
    const dayDiffNumber = moment(date).startOf('day').diff(moment().startOf('day'), 'days', true);

    if (dayDiffNumber === 0) return 'dnes';
    if (dayDiffNumber === -1) return 'včera';
    if (dayDiffNumber < -1 && dayDiffNumber > -7) return `před ${Math.abs(Math.floor(dayDiffNumber))} dny`;
    if (dayDiffNumber <= -7 && dayDiffNumber > -14) return 'před týdnem';
    if (dayDiffNumber <= -14) return `před ${Math.abs(Math.floor(dayDiffNumber / 7))} týdny`;
};
