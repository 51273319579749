import { MutableRefObject, useEffect } from 'react';

const useOnClickOutside = (
    ref: MutableRefObject<Node>,
    handler: (e: MouseEvent | TouchEvent) => void,
    nodesToIgnore?: MutableRefObject<Node>[]
) => {
    useEffect(() => {
        const listener = (event: MouseEvent | TouchEvent) => {
            const isTargetHTMLElement = (target: EventTarget): target is Node => target instanceof HTMLElement;

            if (!ref.current || !isTargetHTMLElement(event.target) || ref.current.contains(event.target)) {
                return;
            }

            if (nodesToIgnore) {
                const shouldIgnoreClick = nodesToIgnore.some((node) => {
                    if (!isTargetHTMLElement(event.target)) {
                        return false;
                    }

                    return node.current.contains(event.target);
                });

                if (shouldIgnoreClick) {
                    return;
                }
            }

            handler(event);
        };

        document.addEventListener('mousedown', listener);
        document.addEventListener('touchstart', listener);

        return () => {
            document.removeEventListener('mousedown', listener);
            document.removeEventListener('touchstart', listener);
        };
    }, [ref, handler, nodesToIgnore]);
};

export default useOnClickOutside;
