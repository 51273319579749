import Link from 'next/link';
import { rem } from 'polished';
import styled from 'styled-components';
import { breakpoints } from '../../styles/theme';

export const Background = styled.div`
    background: ${({ theme }) => theme.colors.secondaryGrey};
    color: ${({ theme }) => theme.colors.grey};
`;

export const AnimeSections = styled.div`
    height: ${rem(120)};
    background: #f6f6f6;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: row;

    @media (min-width: ${breakpoints.sm}px) {
        height: ${rem(60)};
        justify-content: center;
    }
`;

export const AnimeSection = styled.div`
    font-size: ${({ theme }) => theme.fontSizes.textBase};
    font-weight: ${({ theme }) => theme.fontWeights.textBold};
    color: ${({ theme }) => theme.colors.black};
    font-style: italic;
    display: flex;
    align-items: center;
    gap: ${rem(20)};
    width: 45%;
    justify-content: center;

    @media (min-width: ${breakpoints.sm}px) {
        width: 25%;
    }

    @media (min-width: ${breakpoints.lg}px) {
        width: 15%;
    }
`;

export const AnimeImg = styled.img`
    width: ${rem(35)};
    height: ${rem(35)};
`;

export const Navigation = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: ${({ theme }) => theme.fontSizes.textBase};
    padding-top: ${rem(70)};
    margin: 0 10%;

    @media (min-width: ${breakpoints.lg}px) {
        margin: 0;
    }
`;

export const NavigationSection = styled.div`
    display: flex;
    flex: 50%;
    flex-direction: column;
    margin-bottom: ${rem(70)};

    @media (min-width: ${breakpoints.xs}px) {
        flex: 50%;
    }

    @media (min-width: ${breakpoints.lg}px) {
        flex: unset;
    }
`;

export const NavigationTitle = styled.span`
    font-weight: ${({ theme }) => theme.fontWeights.textBold};
    font-size: ${({ theme }) => theme.fontSizes.textMedium};
    padding-bottom: ${rem(20)};
    color: ${({ theme }) => theme.colors.black};
`;

export const NavigationItem = styled.div`
    cursor: pointer;
    padding-bottom: ${rem(15)};
    display: flex;
    align-items: center;
    text-decoration: unset;
`;

export const NavigationLink = styled(Link)<{ withoutMargin?: boolean }>`
    margin-left: ${(props) => (props.withoutMargin ? 0 : rem(10))};
    text-decoration: unset;

    &:hover {
        text-decoration: underline;
    }
`;

export const CatalogSection = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
`;

export const CatalogImage = styled.img`
    width: ${rem(60)};
    height: ${rem(90)};
`;

export const CatalogDescription = styled.p`
    width: ${rem(200)};
    margin: 0;
    padding-left: ${rem(15)};
    line-height: ${rem(20)};
`;

export const NewsletterSection = styled.div`
    background: rgba(116, 120, 129, 0.1);
    padding: ${rem(30)};
    border-radius: ${rem(8)};
`;

export const NewsletterTitle = styled.span`
    font-weight: ${({ theme }) => theme.fontWeights.textBold};
    font-size: ${({ theme }) => theme.fontSizes.h6};
    color: ${({ theme }) => theme.colors.black};
`;

export const NewsletterDescription = styled.p`
    line-height: ${rem(20)};
`;

export const Bottom = styled.div`
    display: flex;
    padding-bottom: ${rem(40)};
    flex-direction: column;
    justify-content: center;
    gap: ${rem(20)};
    margin: 0 5%;

    @media (min-width: ${breakpoints.md}px) {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    @media (min-width: ${breakpoints.lg}px) {
        margin: 0;
    }
`;

export const CopyrightSection = styled.div`
    font-size: ${({ theme }) => theme.fontSizes.textSmall};
    display: flex;
    flex-direction: column;
    gap: ${rem(10)};
    align-items: center;
    color: ${({ theme }) => theme.colors.dark};

    @media (min-width: ${breakpoints.md}px) {
        flex-direction: row;
    }
`;

export const CopyrightLabel = styled.span`
    padding-right: ${rem(15)};
    cursor: pointer;
`;

export const CopyrightLink = styled(Link)`
    padding-right: ${rem(15)};
    color: inherit;
    text-decoration: none;
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }
`;

export const SocialSection = styled.div`
    display: flex;
    justify-content: center;
    margin-top: ${rem(20)};

    @media (min-width: ${breakpoints.md}px) {
        margin-top: 0;
    }
`;

export const SuccessMessage = styled.span`
    margin: ${rem(5)} 0;
    display: block;
    font-size: ${({ theme }) => theme.fontSizes.textSmall};
    color: ${({ theme }) => theme.colors.black};
    text-align: left;
`;
