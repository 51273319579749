import { rem } from 'polished';
import styled from 'styled-components';
import { getMaxPageWidth } from '../../constants';
import { breakpoints } from '../../styles/theme';

export const Wrapper = styled.div<{ withPadding: boolean; sidePadding?: number; mobileSidePadding?: number }>`
    max-width: ${getMaxPageWidth()}px;
    margin: 0 auto;
    padding-top: ${(props) => (props.withPadding ? rem(40) : 0)};
    padding-bottom: ${(props) => (props.withPadding ? rem(40) : 0)};
    padding-left: ${(props) =>
        props.mobileSidePadding || props.sidePadding ? rem(props.mobileSidePadding || props.sidePadding) : 0};
    padding-right: ${(props) =>
        props.mobileSidePadding || props.sidePadding ? rem(props.mobileSidePadding || props.sidePadding) : 0};

    @media (min-width: ${breakpoints.md}px) {
        padding-left: ${(props) => (props.sidePadding ? rem(props.sidePadding) : 0)};
        padding-right: ${(props) => (props.sidePadding ? rem(props.sidePadding) : 0)};
    }
`;
